import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')];

export const server_loads = [0];

export const dictionary = {
	"/(public)": [14,[3]],
	"/(public)/about": [24,[3]],
	"/(app)/account": [8,[2]],
	"/(app)/account/favorites": [9,[2]],
	"/(app)/account/portfolio": [10,[2]],
	"/(app)/account/profile": [11,[2]],
	"/(public)/affiliate": [25,[3]],
	"/(public)/affiliate/terms": [26,[3]],
	"/(public)/asin-auto-ungating": [27,[3]],
	"/(public)/(posts)/blog": [15,[3]],
	"/(public)/(posts)/blog/[slug]": [16,[3,4]],
	"/(public)/(posts)/changelog": [17,[3]],
	"/(public)/(posts)/changelog/[slug]": [18,[3,5]],
	"/(public)/contact": [28,[3]],
	"/(public)/disclaimer": [29,[3]],
	"/(public)/(posts)/docs": [19,[3]],
	"/(public)/(posts)/docs/[category]/[slug]": [20,[3,6]],
	"/(app)/employees": [12,[2]],
	"/(app)/employees/[id]": [13,[2]],
	"/(public)/ip-claim-database": [30,[3]],
	"/(public)/ip-claim-database/suggestion": [31,[3]],
	"/(public)/ip-claims/[id]": [32,[3]],
	"/(public)/leads/[id]": [33,[3]],
	"/(public)/privacy": [34,[3]],
	"/(public)/roadmap": [35,[3]],
	"/(public)/(simple)/sign-in": [23,[7]],
	"/(public)/(posts)/tags": [21,[3]],
	"/(public)/(posts)/tags/[slug]": [22,[3]],
	"/(public)/terms": [36,[3]],
	"/(public)/where-to-buy-products-to-sell-on-amazon-fba": [37,[3]]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};